import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Step,
  StepButton,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { hostname, parseError } from "../../utils";

const steps = ["Domain", "IDP Metadata"];

export default function SsoWizard({
  open,
  onClose,
  companyId,
  getToken,
  eventListener,
}: {
  open: boolean;
  companyId: string;
  getToken: Function;
  onClose: () => void;
  eventListener: Function;
}) {
  const [activeStep, setActiveStep] = useState(0);
  const [domain, setDomain] = useState("");
  const [metadataUrl, setMetadataUrl] = useState("");
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  const submitDomain = (e: any) => {
    e.preventDefault();
    if (!domain) return;
    setActiveStep(activeStep + 1);
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
  };

  const submitSsoConfig = async (e: any) => {
    e.preventDefault();
    const currentToken = await getToken();
    await axios
      .post(
        `${hostname}/sso/`,
        {
          companyId,
          domain,
          metadataUrl,
        },
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      )
      .then(() => {
        onClose();
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>SSO SAML Wizard</DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color="inherit">{label}</StepButton>
            </Step>
          ))}
        </Stepper>
        <React.Fragment>
          {activeStep == 0 && (
            <form onSubmit={submitDomain}>
              <Typography style={{ marginBottom: 20, marginTop: 20 }}>
                Enter the domain you would like to setup for Single Sign-On
              </Typography>
              <TextField
                style={{ marginBottom: 20 }}
                label="SSO Domain"
                value={domain}
                name="domain"
                onChange={(e) => setDomain(e.target.value)}
                fullWidth
                required
              />
              <Button
                mat-button
                variant="outlined"
                type="submit"
                fullWidth
                style={{ marginBottom: 20 }}
              >
                Next
              </Button>
            </form>
          )}
          {activeStep == 1 && (
            <form onSubmit={submitSsoConfig}>
              <Typography style={{ marginBottom: 20, marginTop: 20 }}>
                Parallel AI SP Fields
              </Typography>

              <TextField
                label="Single Sign-On Url"
                fullWidth
                value={"https://api.parallellabs.app/saml/sso/" + domain}
                disabled
                style={{ marginBottom: 20 }}
              />
              <TextField
                label="Entity Id"
                fullWidth
                value={
                  "https://api.parallellabs.app/saml/sso/metadata/" + domain
                }
                disabled
              />
              <Divider />

              <Typography style={{ marginBottom: 20, marginTop: 40 }}>
                Enter your IDP metadata URL
              </Typography>
              <TextField
                label="Metadata URL"
                value={metadataUrl}
                name="metadataUrl"
                onChange={(e) => setMetadataUrl(e.target.value)}
                fullWidth
                required
                style={{ marginBottom: 20 }}
              />
              <Button
                mat-button
                variant="outlined"
                type="submit"
                fullWidth
                style={{ marginBottom: 20 }}
              >
                Submit
              </Button>
            </form>
          )}
        </React.Fragment>
      </DialogContent>
    </Dialog>
  );
}
