import { User } from "firebase/auth";
import { Firestore } from "firebase/firestore";

export default ({
  db,
  mode,
  currentUser,
  id,
  token,
  getToken,
  eventListener,
  openDialog,
  subScreenName,
  instanceId,
}: {
  db: Firestore;
  mode: string;
  currentUser: any | User;
  id: string;
  token: any | string;
  getToken: Function;
  eventListener: Function;
  openDialog: Function;
  subScreenName: string | undefined;
  instanceId: string | undefined;
}) => {
  return <div></div>;
};
