import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ModelSelector from "../../components/ModelSelector";
import WorkflowOutputSelector from "./WorkflowOutputSelector";

export default ({
  node,
  nodes,
  edges,
  setNode,
  currentPlan,
}: {
  node: any;
  nodes: Array<any>;
  edges: Array<any>;
  setNode: (node: any) => void;
  currentPlan: string;
}) => {
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [inputFields, setInputFields] = useState<Array<any>>([]);
  const [model, setModel] = useState("");
  const [output, setOutput] = useState("");
  const [prompt, setPrompt] = useState("");
  const [parentInputFields, setParentInputFields] = useState<Array<any>>([]);
  const [extractType, setExtractType] = useState("");
  const [documentName, setDocumentName] = useState("");

  const determineParentInputFields = () => {
    const parentEdge = edges.find((x) => x.target === node.id);
    if (!parentEdge) return;
    const parentNode = nodes.find((x) => x.id === parentEdge.source);
    if (!parentNode || !parentNode.data.inputFields) return;
    console.log("parent node", parentNode);
    const pif = parentNode.data.inputFields.map((x: any) =>
      x.label.replace(/\s/g, "_").toLowerCase()
    );
    console.log(pif);
    setParentInputFields(pif);
  };

  useEffect(() => {
    console.log("node", node);
    if (!node.data || !node.data.type) return;
    if (node.data.type === "input") {
      setInputFields(node.data.inputFields || []);
    } else {
      setInputFields([]);
    }
    if (node.data.type === "output") {
      setOutput(node.data.output || "log");
      setDocumentName(node.data.documentName || "");
    }
    if (node.data.type === "llm") {
      setModel(node.data.model || "");
      setPrompt(node.data.prompt || "");
    } else {
      setModel("");
      setPrompt("");
    }
    if (node.data.type === "fields") {
      setExtractType(node.data.extractType || "json");
      setInputFields(node.data.inputFields || []);
    }
    setType(node.data.type);
    setName(node.data.name);
    determineParentInputFields();
  }, [node]);

  useEffect(() => {
    console.log("updating node", node);
    if (!node.data) return;
    const fields: any = {
      name,
      type,
    };
    if (type === "input") {
      fields["inputFields"] = inputFields;
    } else if (type === "output") {
      fields["output"] = output;
      fields["documentName"] = documentName;
    } else if (type === "llm") {
      fields["model"] = model;
      fields["prompt"] = prompt;
    } else if (type === "fields") {
      fields["extractType"] = extractType;
      fields["inputFields"] = inputFields;
    }

    setNode({
      ...node,
      data: {
        ...node.data,
        ...fields,
      },
    });
  }, [
    name,
    type,
    inputFields,
    model,
    output,
    prompt,
    extractType,
    documentName,
  ]);

  if (!node.data) return <Typography>Select node to edit</Typography>;

  return (
    <Box>
      <Typography variant="h6">{type.toUpperCase()}</Typography>
      <TextField
        label="Name"
        value={name}
        fullWidth
        onChange={(e) => setName(e.target.value)}
        sx={{ mt: 2 }}
      />

      {type === "llm" && (
        <React.Fragment>
          <ModelSelector
            currentPlan={currentPlan}
            value={model}
            onChange={(e: any) => setModel(e.target.value)}
          />
          {parentInputFields.length > 0 && (
            <React.Fragment>
              <Typography variant="body1" sx={{ mt: 1, ml: 1 }}>
                Input Fields
              </Typography>
              <Box sx={{ mt: 1, ml: 1 }}>
                {parentInputFields.map((field, index) => (
                  <Chip
                    key={index}
                    sx={{ mr: 1, mb: 1 }}
                    label={"{input." + field + "}"}
                  />
                ))}
              </Box>
            </React.Fragment>
          )}
          <TextField
            fullWidth
            sx={{ mt: 2 }}
            multiline
            rows={3}
            label="Prompt"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
          />
        </React.Fragment>
      )}

      {type === "fields" && (
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Extract Type</InputLabel>
          <Select
            value={extractType}
            label="Extract Type"
            onChange={(e) => setExtractType(e.target.value)}
          >
            <MenuItem value={"json"}>JSON</MenuItem>
            <MenuItem value={"llm"}>LLM</MenuItem>
          </Select>
        </FormControl>
      )}

      {inputFields.map((field, index) => (
        <Card variant="outlined" key={index} sx={{ mt: 1 }}>
          <CardContent>
            <Typography>{`Field ${index + 1}`}</Typography>
            <TextField
              label="Label"
              value={field.label}
              sx={{ mt: 2 }}
              fullWidth
              onChange={(e) => {
                field.label = e.target.value;
                setInputFields([...inputFields]);
              }}
            />
            {type === "fields" && extractType === "llm" && (
              <TextField
                label="Description"
                value={field.description}
                sx={{ mt: 2 }}
                fullWidth
                onChange={(e) => {
                  field.description = e.target.value;
                  setInputFields([...inputFields]);
                }}
              />
            )}
            {field.fieldType === "input" && (
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel>Type</InputLabel>
                <Select
                  value={field.type}
                  label="Type"
                  sx={{ mt: 1 }}
                  onChange={(e) => {
                    field.type = e.target.value;
                    setInputFields([...inputFields]);
                  }}
                >
                  <MenuItem value={"text"}>Text</MenuItem>
                  <MenuItem value={"textarea"}>Text Area</MenuItem>
                  <MenuItem value={"file"}>File</MenuItem>
                </Select>
              </FormControl>
            )}
          </CardContent>
        </Card>
      ))}

      {type === "input" && (
        <Button
          sx={{ mt: 2 }}
          variant="outlined"
          onClick={() =>
            setInputFields([
              ...inputFields,
              { label: "", type: "text", fieldType: "input" },
            ])
          }
        >
          Add Input Field
        </Button>
      )}

      {type === "fields" && (
        <Button
          sx={{ mt: 2 }}
          variant="outlined"
          onClick={() =>
            setInputFields([
              ...inputFields,
              { label: "", type: "text", fieldType: "extract" },
            ])
          }
        >
          Add Extract Field
        </Button>
      )}

      {type === "output" && (
        <React.Fragment>
          <WorkflowOutputSelector
            value={output}
            onChange={(e: any) => setOutput(e.target.value)}
          />
          {output === "document" && (
            <TextField
              fullWidth
              sx={{ mt: 2 }}
              label="Document Name"
              value={documentName}
              onChange={(e) => setDocumentName(e.target.value)}
            />
          )}
        </React.Fragment>
      )}
    </Box>
  );
};
