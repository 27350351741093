import { Container, Grid, Link, Typography } from "@mui/material";
import { getInstanceConfig } from "../utils";

export default function Footer() {
  const instanceConfig = getInstanceConfig("dark");
  return (
    <Container>
      <Grid container style={{ marginTop: 55, marginBottom: 25 }}>
        <Grid item xs={12} sm={3} style={{ textAlign: "center" }}>
          <Link href="/privacy" underline="hover" style={{ marginRight: 10 }}>
            Privacy
          </Link>
          <span style={{ marginRight: 10 }}>/</span>
          <Link href="/terms" underline="hover">
            Terms
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} style={{ textAlign: "center" }}>
          <Typography variant="caption">
            Copyright {instanceConfig.title} 2024. All rights reserved.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          style={{
            textAlign: "center",
            display: instanceConfig.isMainSite ? "block" : "none",
          }}
        >
          <Link
            href="#"
            underline="hover"
            style={{ marginRight: 10 }}
            onClick={() =>
              window.open("https://discord.gg/55t6GQSJbM", "_blank")
            }
          >
            Support
          </Link>
          <span style={{ marginRight: 10 }}>/</span>
          <Link href="/blog" underline="hover" style={{ marginRight: 10 }}>
            Blog
          </Link>
          <span style={{ marginRight: 10 }}>/</span>
          <Link
            href="https://parallelai.trackdesk.com/sign-up"
            underline="hover"
          >
            Affiliate Program
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
}
