import { RestartAltOutlined, WebStoriesOutlined } from "@mui/icons-material";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Preview from "@mui/icons-material/Preview";
import RefreshOutlined from "@mui/icons-material/RefreshOutlined";
import WorkHistoryOutlined from "@mui/icons-material/WorkHistoryOutlined";
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { User } from "firebase/auth";
import React, { useState } from "react";
import LoadingScreen from "../../components/LoadingScreen";
import { Company } from "../../types";
import { hostname, parseError } from "../../utils";
import ProjectDialog from "../projects/ProjectDialog";
import ReportViewer from "../projects/ReportViewer";

export default function Projects({
  currentUser,
  getToken,
  eventListener,
  companyId,
  company,
}: {
  getToken: Function;
  eventListener: Function;
  currentUser: any | User;
  companyId: string;
  company: Company;
}) {
  const [init, setInit] = useState(false);
  const [projects, setProjects] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(true);
  const [projectDialogOpen, setProjectDialogOpen] = useState(false);
  const [reportViewerOpen, setReportViewerOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState<any>({ report: "" });
  const [refreshing, setRefreshing] = useState(false);
  const [blogDialogOpen, setBlogDialogOpen] = useState(false);
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(1);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    fetchProjects(value);
  };

  const fetchProjects = async (page: number) => {
    const currentToken = await getToken();
    await axios
      .get(`${hostname}/projects`, {
        params: {
          companyId,
          perPage: 25,
          page: page,
        },
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        setPages(res.data.pages);
        setProjects(res.data.projects);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setInit(true);
        setLoading(false);
      });
  };

  const refreshData = async () => {
    setRefreshing(true);
    await fetchProjects(page);
    setRefreshing(false);
  };

  const previewProject = (project: any) => {
    setSelectedProject(project);
    setReportViewerOpen(true);
  };

  const deleteProject = async (project: any) => {
    const currentToken = await getToken();
    setRefreshing(true);
    await axios
      .delete(`${hostname}/projects/${project.id}`, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        refreshData();
      });
  };

  const FormatStatus = ({ project }: { project: any }) => {
    const status = project.status
      .split("_")
      .map((x: string) => {
        return x[0].toUpperCase() + x.slice(1);
      })
      .join("");
    return status;
  };

  const formatType = (project: any) => {
    return project.type
      .split("_")
      .map((x: string) => {
        return x[0].toUpperCase() + x.slice(1);
      })
      .join("");
  };

  const restartProject = async (project: any) => {
    const currentToken = await getToken();
    console.log("Restarting project");
    axios
      .post(
        `${hostname}/projects/${project.id}/restart`,
        {},
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      )
      .then((res) => {
        refreshData();
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  const base64ToBlob = (base64: string, mimeType: string) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  };

  const downloadPdf = async (projectId: string) => {
    const currentToken = await getToken();
    axios
      .get(`${hostname}/projects/${projectId}/download/pdf`, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        const blo = base64ToBlob(res.data.b64, res.data.mimeType);
        const url = window.URL.createObjectURL(blo);
        const a = document.createElement("a");
        a.href = url;
        a.download = res.data.filename; // Specify the file name
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  if (currentUser.uid && !init) {
    fetchProjects(page);
  }

  if (loading) {
    return <LoadingScreen open={true} />;
  }

  return (
    <Box>
      <Button
        sx={{ mt: 2 }}
        startIcon={<WorkHistoryOutlined />}
        onClick={() => setProjectDialogOpen(true)}
      >
        Create Project
      </Button>
      <Button
        sx={{ ml: 1, mt: 2, display: "none" }}
        startIcon={<WebStoriesOutlined />}
        onClick={() => setBlogDialogOpen(true)}
      >
        Create Blog
      </Button>

      <Typography sx={{ mt: 2 }}>
        Projects is a beta feature with limited functionality at the moment.
        Please contact us if you have any questions or feedback.
      </Typography>

      <Paper style={{ marginTop: 20, marginBottom: 40 }} variant="outlined">
        {refreshing && <LinearProgress />}
        {!refreshing && <div style={{ height: 4 }}></div>}
        <IconButton
          onClick={() => refreshData()}
          style={{ float: "right", marginRight: 5 }}
        >
          <RefreshOutlined />
        </IconButton>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="row">
                  Name
                </TableCell>
                <TableCell>Objective</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Type</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projects.map((project) => (
                <TableRow key={project.id}>
                  <TableCell>{project.name}</TableCell>
                  <TableCell>{project.objective}</TableCell>
                  <TableCell>{project.created}</TableCell>
                  <TableCell>
                    <FormatStatus project={project} />
                  </TableCell>
                  <TableCell>{formatType(project)}</TableCell>
                  <TableCell>
                    <div style={{ display: "flex" }}>
                      {project.status === "completed" ? (
                        <React.Fragment>
                          <IconButton onClick={() => previewProject(project)}>
                            <Preview />
                          </IconButton>
                        </React.Fragment>
                      ) : (
                        <div style={{ flexGrow: 1 }}></div>
                      )}
                      <IconButton
                        sx={{
                          display:
                            project.status === "failed" ? "block" : "none",
                        }}
                        onClick={() => restartProject(project)}
                      >
                        <RestartAltOutlined />
                      </IconButton>
                      <IconButton onClick={() => deleteProject(project)}>
                        <DeleteOutline />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination page={page} count={pages} onChange={handlePageChange} />
        </TableContainer>
      </Paper>
      <ProjectDialog
        title="Create Project"
        articleOnly={false}
        open={projectDialogOpen}
        onClose={() => {
          setProjectDialogOpen(false);
          fetchProjects(1);
        }}
        eventListener={eventListener}
        getToken={getToken}
        company={company}
        onCreateProject={() => console.log("created project")}
      />
      <ReportViewer
        eventListener={eventListener}
        open={reportViewerOpen}
        onClose={() => setReportViewerOpen(false)}
        id={selectedProject.id}
        content={selectedProject.report}
        title={selectedProject.name}
        downloadPdf={downloadPdf}
      />
    </Box>
  );
}
