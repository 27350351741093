import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import axios from "axios";
import { signInWithCustomToken } from "firebase/auth";
import "firebase/compat/auth";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { identify } from "../capture";
import { hostname, parseError } from "../utils";
const darkLogo = require("../img/logo_transparent_dark.png");
const logo = require("../img/logo_transparent.png");

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function LoginSso({ auth, mode }: { auth: any; mode: string }) {
  const [domain, setDomain] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  let query = useQuery();
  const token = query.get("token");
  if (token) {
    signInWithCustomToken(auth, token)
      .then((userCredential) => {
        identify(userCredential.user);
        navigate("/dashboard/companies");
      })
      .catch(() => {
        setErrorMessage("Invalid token");
      });
  }

  const submitForm = (e: any) => {
    e.preventDefault();
    setErrorMessage("");
    axios
      .post(`${hostname}/sso/login`, {
        domain,
      })
      .then((res) => {
        // @ts-ignore
        window.location.href = res.data.redirectUrl;
      })
      .catch((err) => {
        setErrorMessage(parseError(err));
      });
  };

  return (
    <Box>
      <Paper
        style={{
          width: 300,
          top: "50%",
          left: "50%",
          position: "fixed",
          marginTop: -150,
          marginLeft: -150,
          padding: 10,
        }}
      >
        <Grid container>
          <Grid item xs={12} style={{ textAlign: "center", margin: 20 }}>
            <img
              style={{ margin: "auto", height: 35 }}
              src={mode === "dark" ? darkLogo : logo}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography variant="h6" component={"h6"}>
              SSO Login
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ textAlign: "center", marginTop: 20, marginBottom: 20 }}
          >
            <form onSubmit={submitForm}>
              <TextField
                fullWidth
                label="Domain"
                onChange={(e) => setDomain(e.target.value)}
                value={domain}
                required
                style={{ marginBottom: 10 }}
              />
              {errorMessage && (
                <Typography style={{ marginBottom: 10 }}>
                  {errorMessage}
                </Typography>
              )}
              <Button variant="outlined" type="submit" fullWidth>
                Login
              </Button>
            </form>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
