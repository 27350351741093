import React, { useState } from "react";

import { DescriptionOutlined, MessageOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  TextField,
  Typography,
  debounce,
} from "@mui/material";
import axios from "axios";
import { hostname, parseError } from "../../utils";

export default function SearchBar({
  companyId,
  token,
  getToken,
  eventListener,
  openDialog,
  navigate,
  setTab,
}: {
  companyId: string;
  token: any | string;
  getToken: Function;
  eventListener: Function;
  openDialog: Function;
  navigate: Function;
  setTab: Function;
}) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);

  const inputChanged = async (e: any) => {
    if (!e.target || !e.target.value || e.target.value.length === 0) return;
    const currentToken = await getToken();
    setLoading(true);
    axios
      .get(`${hostname}/search/query`, {
        params: {
          companyId: companyId,
          query: e.target.value,
        },
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        setOpen(true);
        setOptions(res.data.results);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const searchDelayed = debounce(inputChanged, 700);

  const selectedOption = (e: any, value: any) => {
    if (value.type === "message") {
      setTab();
      navigate("/dashboard/companies/" + companyId + "/chats/" + value.chatId);
    } else if (value.type === "document") {
      window.location.href =
        "/dashboard/companies/" + companyId + "/documents/" + value.documentId;
    }
  };

  return (
    <Autocomplete
      id="search"
      sx={{ width: "100%", mt: 2 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      filterOptions={(options, state) => options}
      isOptionEqualToValue={(option, value) => option.title === value.title}
      onInputChange={searchDelayed}
      onChange={selectedOption}
      getOptionLabel={(option) => option.label}
      options={options}
      loading={loading}
      renderInput={(params) => <TextField {...params} label="Search" />}
      renderOption={(params, option) => {
        console.log(params);
        console.log(option);
        return (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...params}
          >
            {option.type === "message" && <MessageOutlined sx={{ mr: 1 }} />}
            {option.type === "document" && (
              <DescriptionOutlined sx={{ mr: 1 }} />
            )}
            <Typography>{option.label}</Typography>
          </Box>
        );
      }}
    />
  );
}
