import { useState } from "react";
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Link,
} from "@mui/material";
import capture from "../capture";

export default function ({
  mode,
  setThemeForOnePage,
}: {
  mode: string;
  setThemeForOnePage: Function;
}) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  setThemeForOnePage("dark");

  const handleSubmit = (event: any) => {
    event.preventDefault();
    capture("Contact Us", { name, email, message });
    setName("");
    setEmail("");
    setMessage("");
  };

  return (
    <Box>
      <Container>
        <Grid container style={{ marginTop: 50 }}>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Box component={Link} href="/">
              <Box
                component="img"
                style={{ maxWidth: "30vw" }}
                src={require("../img/logo_transparent_dark.png")}
              />
            </Box>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center", marginTop: 80 }}>
            <Typography variant="h3">
              <span
                style={{
                  backgroundImage: `linear-gradient(240deg, #A3F7BF 0%, #666BE3 100%)`,
                  backgroundSize: "100%",
                  backgroundRepeat: "repeat",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                CUSTOM
              </span>{" "}
              AI Business Solutions
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center", marginTop: 80 }}>
          <Typography variant="h4">
            Engage the team that built Parallel AI.
          </Typography>
        </Grid>
      </Container>
      <Container>
        <Grid
          container
          style={{ marginTop: 100, marginBottom: 100 }}
          spacing={4}
        >
          <Grid item xs={12} sm={4} style={{ textAlign: "center" }}>
            <Typography variant="h4">AI Experts</Typography>
            <Typography variant="body1" style={{ marginTop: 15 }}>
              Unlock the AI potential in your business to increase productivity
              and drive growth. Not sure how to implement AI, or what specific
              tasks it could do? Our team will recommend paths forward, and work
              with you to build a custom AI solution that reduces costs and
              supercharges your existing offerings.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} style={{ textAlign: "center" }}>
            <Typography variant="h4">End-to-End Solutions</Typography>
            <Typography variant="body1" style={{ marginTop: 15 }}>
              Our end-to-end AI services include exploration, recommending
              architecture, development, testing, automation, deployment,
              scaling, and long-term support.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} style={{ textAlign: "center" }}>
            <Typography variant="h4">Support</Typography>
            <Typography variant="body1" style={{ marginTop: 15 }}>
              Our team can guide you through your AI journey with industry
              leading expertise. From architecting and implementing an AI
              solution, to ongoing support, our team can maintain solutions
              long-term after delivery.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid container style={{ marginTop: 50, marginBottom: 50 }}>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <h2>Contact Us</h2>
            <p>Learn more about our custom AI services.</p>
            <form onSubmit={handleSubmit}>
              <TextField
                id="name"
                label="Name"
                value={name}
                onChange={(event) => setName(event.target.value)}
                fullWidth
                required
              />
              <TextField
                style={{ marginTop: 10 }}
                id="email"
                label="Email"
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                fullWidth
                required
              />
              <TextField
                style={{ marginTop: 10 }}
                id="message"
                label="Message"
                value={message}
                onChange={(event) => setMessage(event.target.value)}
                fullWidth
                multiline
                rows={4}
                required
              />
              <Button
                style={{ marginTop: 10 }}
                type="submit"
                variant="outlined"
                fullWidth
                color="primary"
              >
                Submit
              </Button>
            </form>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
