import { styled } from "@mui/material";
import React from "react";
import { Controls } from "reactflow";
import { darkTheme, lightTheme } from "./flowtheme";

const LightControls = styled(Controls)`
  button {
    background-color: ${(props) => lightTheme.controlsBg};
    color: ${(props) => lightTheme.controlsColor};
    border-bottom: 1px solid ${(props) => lightTheme.controlsBorder};

    &:hover {
      background-color: ${(props) => lightTheme.controlsBgHover};
    }

    path {
      fill: currentColor;
    }
  }
`;

const DarkControls = styled(Controls)`
  button {
    background-color: ${(props) => darkTheme.controlsBg};
    color: ${(props) => darkTheme.controlsColor};
    border-bottom: 1px solid ${(props) => darkTheme.controlsBorder};

    &:hover {
      background-color: ${(props) => darkTheme.controlsBgHover};
    }

    path {
      fill: currentColor;
    }
  }
`;

export default ({ mode }: { mode: string }) => {
  return mode === "light" ? <LightControls /> : <DarkControls />;
};
