import { Avatar, CardMedia, Container, Typography } from "@mui/material";
import React from "react";
import { CopyBlock, dracula } from "react-code-blocks";
import { Helmet } from "react-helmet";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import { posts } from "../constants";
import Footer from "../home/Footer";
import Header from "../home/Header";

const BlogPost = ({
  setThemeForOnePage,
}: {
  setThemeForOnePage: (theme: string) => void;
}) => {
  setThemeForOnePage("dark");
  const { name } = useParams();
  if (!name) {
    return (
      <Typography style={{ textAlign: "center", marginTop: 50 }} variant="h5">
        Article Not Found
      </Typography>
    );
  }
  const post = posts.find((x) => x.name === name);
  if (!post) {
    return (
      <Typography style={{ textAlign: "center", marginTop: 50 }} variant="h5">
        Article Not Found
      </Typography>
    );
  }
  // Destructure your post object as needed. This is just an example.
  const { title, body, summary, imageSrc, publishedDate, author } = post;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={summary.substring(0, 150)} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={summary.substring(0, 150)} />
        <meta property="og:image" content={imageSrc} />
        <meta property="og:type" content="article" />
        <meta property="article:published_time" content={publishedDate} />
        <meta name="author" content={author} />
      </Helmet>
      <Header mode="dark" maxWidth={"lg"} />
      <Container maxWidth="lg">
        <div style={{ padding: "20px", marginTop: "20px" }}>
          <Typography variant="h3" component="h1" gutterBottom>
            {title}
          </Typography>
          <Typography
            variant="body1"
            style={{
              marginTop: 15,
              marginBottom: 15,
              flexDirection: "row",
              alignItems: "center",
              display: "flex",
            }}
            gutterBottom
          >
            <Avatar
              src={require("../img/author.jpeg")}
              alt="David Richards"
              sx={{ width: 34, height: 34, marginRight: 1 }}
            />
            David Richards
          </Typography>
          <Typography style={{ marginBottom: 15 }}>{publishedDate}</Typography>
          <CardMedia
            height="300"
            component="img"
            image={imageSrc}
            alt={title}
            sx={{ mt: 2, mb: 4 }}
          />
          <ReactMarkdown
            children={body}
            components={{
              code(props) {
                const { children, className, node, ...rest } = props;
                const match = /language-(\w+)/.exec(className || "");
                return match ? (
                  <CopyBlock
                    language={match[1]}
                    text={String(children).replace(/\n$/, "")}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                ) : (
                  <code style={{ color: "#A3F7BF" }}>{children}</code>
                );
              },
            }}
          />
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default BlogPost;
