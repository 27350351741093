import { Box, Button, Container, Link } from "@mui/material";
import { getLogoMode } from "../utils";

export default function Header({
  mode,
  maxWidth = "xl",
}: {
  mode: string;
  maxWidth: any;
}) {
  const standalone =
    window.navigator &&
    "standalone" in window.navigator &&
    window.navigator.standalone;
  return (
    <Box
      style={{
        width: "100%",
        marginTop: standalone ? 60 : 0,
      }}
    >
      <Container style={{ display: "flex" }} maxWidth={maxWidth}>
        <Link href="/">
          <Box
            component="img"
            src={getLogoMode(mode)}
            alt="Parallel AI  logo"
            style={{
              height: 35,
              marginTop: 5,
              marginRight: 10,
            }}
          />
        </Link>
        <div style={{ flexGrow: 1 }}></div>
        <Button
          component={Link}
          href="/login"
          style={{ marginRight: 15, marginTop: 10 }}
        >
          Login
        </Button>
        <Button
          variant="outlined"
          component={Link}
          href="/signup"
          style={{ marginRight: 20, marginTop: 10 }}
        >
          Sign Up
        </Button>
      </Container>
    </Box>
  );
}
