import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { hostname, parseError } from "../../utils";
import LoadingButton from "../../components/LoadingButton";

export default ({
  open,
  onClose,
  getToken,
  eventListener,
  companyId,
  currentBlog,
  path,
}: {
  open: boolean;
  onClose: () => void;
  getToken: Function;
  eventListener: Function;
  companyId: string;
  currentBlog: any;
  path: string;
}) => {
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    console.log("Submitted form");
    setLoading(true);
    const currentToken = await getToken();
    await axios
      .post(
        `${hostname}/blogs/category`,
        {
          blog: currentBlog,
          companyId: companyId,
          path: path,
          category: category,
        },
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      )
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create Category</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Choose options for creating a new blog category
        </DialogContentText>

        <TextField
          sx={{ mt: 2 }}
          fullWidth
          label="Category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={loading} variant="outlined" onClick={onSubmit}>
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
