import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  Typography,
} from "@mui/material";

export default ({ instanceConfig }: { instanceConfig: any }) => {
  return (
    <Container sx={{ display: instanceConfig.isMainSite ? "block" : "none" }}>
      <Grid container style={{ marginTop: 200, marginBottom: 100 }}>
        <Grid xs={12} style={{ textAlign: "center" }} item>
          <Typography
            variant="h5"
            component="h5"
            style={{
              background: instanceConfig.gradiantColor,
              WebkitBackgroundClip: "text",
              backgroundClip: "text",
              color: "transparent",
            }}
          >
            FAQs
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <div
            style={{ marginLeft: "10vw", marginRight: "10vw", marginTop: 30 }}
          >
            <Accordion variant="outlined">
              <AccordionSummary expandIcon={<ArrowDropDown />}>
                <Typography>
                  How does {instanceConfig.title} knowledge base make my AI
                  smarter?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {instanceConfig.title} knowledge base transforms AI into a
                  powerhouse for your business, using your unique data to fully
                  automate tasks with complete contextual understanding.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion variant="outlined">
              <AccordionSummary expandIcon={<ArrowDropDown />}>
                <Typography>What models do you support?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {instanceConfig.title} supports OpenAI's GPT-4, Google Gemini,
                  Anthropic Claude, leading open-source models, and more,
                  offering a diverse range of AI capabilities.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion variant="outlined">
              <AccordionSummary expandIcon={<ArrowDropDown />}>
                <Typography>What integrations do you support?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {instanceConfig.title} integrates with an extensive suite of
                  tools including Google Drive, Notion, Slack, Discord,
                  Confluence, Zapier, and more, continuously expanding its
                  compatibility.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion variant="outlined">
              <AccordionSummary expandIcon={<ArrowDropDown />}>
                <Typography>
                  Can I try {instanceConfig.title} for Free?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Yes, we offer a free-forever tier with a monthly limit.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};
